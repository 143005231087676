<template>
    <div>
        <div>
            <div class="col-lg-12">
                <h3 class="text-primary mt-2 mb-4 font-poppins-semibold ">Bull Quarantine</h3>

                <div class="row">
                    <div class="col-lg-2">
                        <validated-vue-select class="c-input-select field-required"
                                              :options="typeOptions"
                                              label="Type of Quarantine"
                                              v-model="selectedType" :rules="{required:true}"></validated-vue-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="color-txt" v-if="selectedType !==''">
            <bull-quarantine :clear-data="clearData" :type="selectedType"></bull-quarantine>
        </div>
    </div>
</template>

<script>
import BullQuarantine from '../../components/BullQuarntine/BullQuarantine';

export default {
    name: 'BullQuaratineTwo',
    components: { BullQuarantine },
    data () {
        return {
            selectedType: '',
            clearData: false,
            typeOptions: [
                { label: 'New Bull Quarantine', value: 'New Bull Quarantine' },
                { label: 'Existing Bull Update', value: 'Existing Bull Update' }
            ]
        };
    },
    watch: {
        selectedType (newValue) {
            this.ChangeData();
        }
    },
    methods: {
        ChangeData () {
            this.clearData = !this.clearData;
        }
    }
};
</script>

<style scoped>

</style>
